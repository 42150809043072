import { Redirect } from "react-router-dom";

import React, { Component } from 'react';
import Navbar from './navbar';
import MobileButton from './mobileButton';

import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Alert from 'react-bootstrap/Alert';

import mainStyles from '../styles/mainStyles.js';
import homeStyles from '../styles/homeStyles.js';
import '../styles/bootstrapOverwriteStyles.css';

const Mousetrap = require("mousetrap");

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {text: '', isProtected: false, password: '', expirationTime: '15m', slug: '', showModal: false, 
      emptySubmit: false}
    this.onChangeText = this.onChangeText.bind(this)
    this.onChangePassword = this.onChangePassword.bind(this)
    this.onChangeExpirationTime = this.onChangeExpirationTime.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.showModal = this.showModal.bind(this)
    this.closeModal = this.closeModal.bind(this)
  }

  onCtrlS() {
    Mousetrap.bind(['ctrl+s', 'meta+s'], (e) => {
      if (e.preventDefault) {
        e.preventDefault()
      } 
      else {
        e.returnValue = false
      }
      if (this.state.showModal){
        this.onSubmit()
      }
      else {
        this.showModal()
      }
    })
  }

  onCtrlV() {
    Mousetrap.bind(['ctrl+v', 'meta+v'], async(e) => {
      const isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
      var isChromium = window.chrome;
      if(isChrome || isChromium){
        const text = await navigator.clipboard.readText()
        await this.setState({text: text})
      }
    })
  }
  
  componentDidMount() {
    this.onCtrlS()
    this.onCtrlV()
  }

  componentWillUnmount() { 
    Mousetrap.unbind('ctrl+s');
    Mousetrap.unbind('meta+s');
    Mousetrap.unbind('ctrl+v');
    Mousetrap.unbind('meta+v');
  }

  onChangeText(e) {
    this.setState({text: e.target.value})
  }

  onChangeExpirationTime(e) {
    this.setState({expirationTime: e.target.value})
  }

  onChangePassword(e) {
    if(!this.state.isProtected) {
      this.setState({isProtected: true})
    }
    this.setState({password: e.target.value})
  }

  showModal(e) {
    this.setState({showModal: true})
  }

  closeModal(e) {
    this.setState({showModal: false})
  }


  async onSubmit(e) { 
    if (e){
      e.preventDefault();
    }
    if(this.state.text === ''){
      this.setState({emptySubmit: true})
      setTimeout(() => {this.setState({emptySubmit: false})}, 1000)
    }
    else {
      if(this.state.isProtected & this.state.password === ''){ //user entered a password then erased it
        await this.setState({isProtected: false})
      }
      let responseRaw = await fetch(process.env.REACT_APP_BASE_URL + '/createPastebin',
        {
          method: 'POST',
          mode: 'cors',
          headers: { 'Content-Type': 'application/json'},
          body: JSON.stringify({text: this.state.text, isProtected: this.state.isProtected,
           password: this.state.password, expirationTime: this.state.expirationTime}) 
        })
      let response = await responseRaw.json();
      this.setState({slug: response.slug})
      }
    }

  render() {
    return(
      <Container style={mainStyles.containerStyles} fluid={true}>
        <Navbar onButtonPress={this.showModal}/>
        {this.state.slug && (
          <div style= {mainStyles.primaryStyles}>
            <Redirect style= {mainStyles.primaryStyles} to = {{
              pathname: this.state.slug,
              state: { text: this.state.text }
            }} />
          </div>
        )}
        <Form onSubmit={this.onSubmit}>
          <Modal dialogClassName="clean-modal" show={this.state.showModal} onHide={this.closeModal}>
            <Modal.Header style={mainStyles.primaryStyles}  closeButton>
              <Modal.Title>Options </Modal.Title>
            </Modal.Header>
            <Modal.Body style={mainStyles.secondaryStyles}>
              You can just hit <kbd>Ctrl</kbd> + <kbd>S</kbd> to save without a password
              <Form.Group>
                <Form.Label>Expire in </Form.Label>
                <Form.Control  style={mainStyles.secondaryStyles} as="select" value={this.state.expirationTime} onChange={this.onChangeExpirationTime} >
                  <option value='24h'>24 hours</option>
                  <option value='12h'>12 hours</option>
                  <option value='6h'>6 hours</option>
                  <option value='3h'>3 hours</option>
                  <option value='1h'>1 hour</option>
                  <option value='15m'>15 minutes</option>
                  <option value='1m'>1 minute</option>
                </Form.Control>
              </Form.Group>
              <Form.Group>
                <Form.Label>Password</Form.Label>
                <Form.Control style={mainStyles.secondaryStyles} type="password" onChange={this.onChangePassword} placeholder="Optional password" />
              </Form.Group>
              {this.state.emptySubmit && (
                <Alert className="alert">Your pastebin is empty!</Alert>
              )}
            </Modal.Body>
            <Modal.Footer style={mainStyles.primaryStyles}>
              <Button size="lg" variant="primary" onClick={this.onSubmit} block>
                Submit!
              </Button>
            </Modal.Footer>
          </Modal>
          <Form.Group style={homeStyles.overrideStyles}>
            <Form.Control className="mousetrap" value={this.state.text} style={mainStyles.mainAreaStyles} as="textarea" onChange={this.onChangeText} 
                          placeholder="Ctrl + V or start typing"/> 
          </Form.Group>   
        </Form>
        <MobileButton icon={'save'} onClick={this.showModal}/> 
      </Container>
    )
  }
}

export default Home;
