import React, { Component } from 'react';
import { FaHome, FaSave } from 'react-icons/fa';

import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Button from 'react-bootstrap/Button';

import {Link} from "react-router-dom";

import {BrowserView} from 'react-device-detect';

import mainStyles from '../styles/mainStyles.js';
import '../styles/bootstrapOverwriteStyles.css';
import navbarStyles from '../styles/navbarStyles.js';


//TODO: TODO change the color of the hovered on navlink 
//TODO: change the colors of the brand message

class MyNavbar extends Component { 
  constructor(props) {
    super(props); 
    this.state = {brandMessage: 0 }
    this.activeStyling = this.activeStyling.bind(this)
  }

  componentDidMount() {
    // Not setting brand message directly in order to have <KBD> elements without 
    // Dangerous injecting HTML This solution is inelgent
    // TODO: make a liste of 3 states instead, key 1 key 2 and mesage instead
    if (window.location.pathname === '/' ) {
      this.setState({brandMessage: 1})
    }
    else if (window.location.pathname === '/FAQ' || window.location.pathname ===  '/Contact' ){
      this.setState({brandMessage: 2})
    }

    else {
      this.setState({brandMessage: 3})
    }
  }

  activeStyling(page){
    if (page === window.location.pathname){
      return navbarStyles.activeNavStyles
    }
    else {
      return mainStyles.primaryStyles
    }
  }

	render() {
		return(
			<Navbar style={mainStyles.primaryStyles} expand="lg"> 
        <Navbar.Brand  style={mainStyles.primaryStyles} title="click or hit Ctrl-N to start a new bin" href="/">HoldThis.xyz</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" >
          <Nav className="pull-sm-left" >
      			<Nav.Link as={Link} to="/" style={this.activeStyling('/')}>Home</Nav.Link>
            <Nav.Link as={Link} to="/FAQ" style={this.activeStyling('/FAQ')}>FAQ</Nav.Link>
            <Nav.Link as={Link} to="/Contact" style={this.activeStyling('/Contact')}>Contact</Nav.Link>
            <Nav.Link href="https://api.holdthis.xyz/" style={mainStyles.primaryStyles}>API</Nav.Link>
          </Nav>
          <Nav className="mx-auto" style={mainStyles.primaryStyles}>
            <Navbar.Brand style={mainStyles.primaryStyles}>
              <BrowserView>
                {this.state.brandMessage === 1 && (
                  <span>
                    <kbd>Ctrl</kbd> + <kbd>S</kbd> to save you paste, a modal with options will pop up.
                  </span>
                )}
                {this.state.brandMessage === 2 && (
                  <span>
                    <kbd>Ctrl</kbd> + <kbd>H</kbd> to start a new pastebin. 
                  </span>
                )}
                {this.state.brandMessage === 3 && (
                  <span>
                    <kbd>Ctrl</kbd> + <kbd>C</kbd> to copy a link to the current paste.
                  </span>
                )}
              </BrowserView>
            </Navbar.Brand> 
          </Nav> 
          <Nav className="pull-sm-right">
          {this.props.onButtonPress ? 
            <Button className="button" onClick={this.props.onButtonPress}>
              <FaSave size="25" title="click or hit Ctrl-S to save"/>
            </Button> 
            :  
            <Link to="/">
              <Button className="button" block>
                <FaHome style={navbarStyles.buttonContentStyles} size="25" title="click or hit Ctrl-H to start a new paste"/> 
              </Button> 
            </Link>
          }
          
          </Nav>
        </Navbar.Collapse>
      </Navbar>
		)
	}
}

export default MyNavbar;