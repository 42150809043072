const primaryStyles = { //for navbar & modal header & footer
  color: '#ebebeb',
  backgroundColor: '#222222',
  fontFamily: 'Inter UI var alt' 
}

const secondaryStyles = { //for contents
  backgroundColor: '#2a2a2a',
  color: '#ebebeb',
  fontFamily: 'EnvyCodeR',
}

const containerStyles = {
  backgroundColor: '#2a2a2a',
  paddingLeft: 0,
  paddingRight: 0,
  overflowX: 'hidden'
}

const mainAreaStyles = {
  paddingRight: 25,
  paddingLeft: 25, 
  paddingTop: 10, 
  backgroundColor: '#2a2a2a',
  fontSize: '1.2rem',    
  //lineHeight: '.5',
  color: '#ebebeb',
  fontFamily: 'EnvyCodeR',
  marginBottom: 0,
  boxShadow: 'none',
  outline: 'none',
  border: 'none',
  minHeight: '94.5vh', 
}

export default {primaryStyles, secondaryStyles, containerStyles, mainAreaStyles} 