import React, { Component } from "react";
import Navbar from './navbar'
import MobileButton from './mobileButton';
import RedirectToHome from './redirectToHome'


import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Alert from 'react-bootstrap/Alert';

import mainStyles from '../styles/mainStyles';
import '../styles/bootstrapOverwriteStyles.css';

class Contact extends Component {
	constructor(props) { 
		super(props);
    this.state = {name: '', email: '', subject: '', message: '', 
      invalidForm: false, messageFailed: null, showModal: true}
    this.onChangeName = this.onChangeName.bind(this)
    this.onChangeEmail = this.onChangeEmail.bind(this)
    this.onChangeSubject = this.onChangeSubject.bind(this)
    this.onChangeMessage = this.onChangeMessage.bind(this)
    this.validateForm = this.validateForm.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.closeModal = this.closeModal.bind(this)
	}

	onChangeName(e) {
		this.setState({name: e.target.value})
	}

	onChangeEmail(e) { 
		this.setState({email: e.target.value})
	}

  onChangeSubject(e){
    this.setState({subject: e.target.value})
  }

	onChangeMessage(e) { 
		this.setState({message: e.target.value})
	}

  closeModal(e) {
    this.setState({showModal: false})
  }

  validateForm(e) {
    let form = document.getElementsByClassName("form");
    form = form[0]
    if (form.checkValidity() === false) {
      this.setState({invalidForm: true})
      return false
    }
    else {
      return true
    }
  }
  
  async onSubmit(e) { 
    const valid = await this.validateForm()
    if(valid){
      let responseRaw = await fetch(process.env.REACT_APP_BASE_URL + '/sendContactMail',
      {
        method: 'POST',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json'},
        body: JSON.stringify({name: this.state.name, email: this.state.email, 
          subject: this.state.subject, message: this.state.message}) 
      })
      if (responseRaw.status === 200){
        this.setState({messageFailed: false})
        setTimeout(() => {this.setState({showModal: false})}, 3000)
      }
      else {
        this.setState({messageFailed: true})
      }
    }
  }

  render(){
  	return(
  		<Container style={mainStyles.containerStyles} fluid={true}>
        <Navbar/>
        <RedirectToHome/>
    		<div style={mainStyles.mainAreaStyles}>
    			<Modal dialogClassName="clean-modal" show={this.state.showModal} onHide={this.closeModal}>
    			<Modal.Header style={mainStyles.primaryStyles}  closeButton>
              <Modal.Title>Contact us!</Modal.Title>
            </Modal.Header>
            <Modal.Body style={mainStyles.secondaryStyles}>
              <Form className="form" validated={this.state.invalidForm}>
                <Form.Group>
                	<Form.Label>Name</Form.Label>
                	<Form.Control required style={mainStyles.secondaryStyles} type="text" placeholder="Enter your name" onChange={this.onChangeName} />
                  <Form.Control.Feedback type="invalid"> Name field is required. </Form.Control.Feedback>
                </Form.Group>
  							<Form.Group>
  							  <Form.Label>Email address</Form.Label>
  							  <Form.Control required style={mainStyles.secondaryStyles} type="email" placeholder="Enter your email" onChange={this.onChangeEmail}  />
  							  <Form.Control.Feedback type="invalid"> Please provide a proper email. </Form.Control.Feedback>
                  <Form.Text className="text-muted">
  							    We'll never share your email with anyone else.
  							  </Form.Text>
  							</Form.Group>
                <Form.Group>
                  <Form.Label>Subject</Form.Label>
                  <Form.Control required style={mainStyles.secondaryStyles} type="text" placeholder="Subject" onChange={this.onChangeSubject} />
                  <Form.Control.Feedback type="invalid"> Subject field is required. </Form.Control.Feedback>
                </Form.Group>
  							<Form.Group>
  								<Form.Label>Message</Form.Label>
  								<Form.Control className="message" required style={mainStyles.secondaryStyles} as="textarea" onChange={this.onChangeMessage} 
                            placeholder="What do you want to talk about?"/>
                  <Form.Control.Feedback type="invalid"> Can't send an empty message. </Form.Control.Feedback>
  							</Form.Group>
              </Form>
               {this.state.messageFailed && (
                  <Alert className="alert">
                    Something went wrong, Please try agian later.
                  </Alert>
                )}
                {this.state.messageFailed === false && (
                  <Alert className="alert-success">
                    Message sent! We will get back to you ASAP. 
                  </Alert>
                )}             		
            </Modal.Body>
            <Modal.Footer style={mainStyles.primaryStyles}>
              <Button onClick={this.onSubmit} type="submit"  size="lg" variant="primary" block>
                Send message!
              </Button>
            </Modal.Footer>
          </Modal>
		  	<p>
		  		I am the contact form page.
		  	</p>
		  	<p>
		  		Start a new paste by pressing <kbd>Ctrl</kbd> + <kbd>H</kbd>
		  	</p>
        <MobileButton icon={'home'} onClick=""/> 
			  </div>
		  </Container>
  	)
  }
}

export default Contact;
