import React, { Component } from "react";
import { Link } from "react-router-dom";

import Navbar from './navbar'
import MobileButton from './mobileButton';
import RedirectToHome from './redirectToHome'

import Container from 'react-bootstrap/Container';

import mainStyles from '../styles/mainStyles';
import faqtyles from '../styles/faqStyles';


class Faq extends Component {
  render() {
    return(
      <Container style={mainStyles.containerStyles} fluid={true}>
        <Navbar/>
        <RedirectToHome/>
        <div style={mainStyles.mainAreaStyles}>
      		<p style={faqtyles.questionStyles}>Why does this exist?</p>
        		<p style={faqtyles.answerStyles}>
        			Because there were no good-looking pastebin sites left and I thought keyboard short cuts would be a neat feature.
        		</p>
      		<p style={faqtyles.questionStyles}>What is site this written in?</p>
        		<p style={faqtyles.answerStyles}>
        			MongoDB, Express.js, React.js with React-Bootstrap for the styling with custom styling and Node.js. 
        		</p>
      		<p style={faqtyles.questionStyles}>Why?</p>
        		<p style={faqtyles.answerStyles}>
        		  I though learning a new stack would be fun and good for my CV.
        		</p>
      		<p style={faqtyles.questionStyles}>Is this open source?</p>
        		<p style={faqtyles.answerStyles}>
        		  Yes,  <a style={faqtyles.linkStyles} href="https://gitlab.com/quebit/holdthis">Here</a> 
        		</p>
      		<p style={faqtyles.questionStyles}>Is there an API?</p>
        		<p style={faqtyles.answerStyles}>
        		  Yes, <a style={faqtyles.linkStyles} href="https://api.holdthis.xyz/">Here</a> 
        		</p>
      		<p style={faqtyles.questionStyles}>Why is my content limited to a 24 hour life-span?</p>
        		<div style={faqtyles.answerStyles}>
              <ol>
          		<li> To limit nefarious actions. </li>
          		<li> The amount of space on the server is limited.</li>
          		</ol>
            </div>
          <p style={faqtyles.questionStyles}>I've got a question and or compliant, How do I contact you?</p>
            <p style={faqtyles.lastAnswerStyles}>
              You can use the contact form <Link style={faqtyles.linkStyles} to="/contact">Here</Link> or you can email me at: que.holdthis AT gmail DOT COM 
            </p>
          <MobileButton icon={'home'} onClick=""/>
        </div>
    	</Container>
    )
  }
}

export default Faq;
