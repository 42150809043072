import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { FaHome, FaSave } from 'react-icons/fa';
import Button from 'react-bootstrap/Button';
import { MobileView } from "react-device-detect";

import buttonStyles from '../styles/mobileStyles.js';
import '../styles/bootstrapOverwriteStyles.css';

class MobileButton extends Component { 
	render() {
		const icon = this.props.icon;
    let button;
    if (icon === 'save'){
    	button = 
    	<Button style={ buttonStyles.buttonStyles }  size="lg" variant="primary" onClick={this.props.onClick} block>
	    	<FaSave  size="25"/>	
	  	</Button>
    }
    else {
    	button =
    	<Link to="/">
	    	<Button style={ buttonStyles.buttonStyles } size="lg" variant="primary" block>
		    	<FaHome size="25"/>	
		  	</Button>
		  </Link>
    }

		return(
			<MobileView>
				{ button }
			</MobileView>
		)
	}
}

export default MobileButton;
