import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch} from "react-router-dom";

import home from './components/home';
import faq from './components/faq';
import contact from './components/contact';
import result from './components/result';

class App extends Component {
  render() {
    return( 
      <Router> 
        <Switch>
          <Route path="/" exact component={home} />
          <Route path="/faq" exact component={faq} />
          <Route path="/contact" exact component={contact} />
          <Route path="/:id" component={result} /> 
        </Switch>
      </Router>
    )
  }
}

export default App;
