import React, { Component } from "react";
import { Redirect } from "react-router-dom";
const Mousetrap = require("mousetrap");

class RedirectToHome extends Component {
	
	constructor(props) {
    super(props);
    this.state = {pressed: false}
  }

	componentDidMount() {
    Mousetrap.bind(['ctrl+h', 'meta+h'], (e) => {
      if (e.preventDefault) {
        e.preventDefault()
      } 
      else {
        e.returnValue = false
      }
      this.setState({pressed: true}) 
    })
  }

  componentWillUnmount() {
    Mousetrap.unbind('ctrl+h');
    Mousetrap.unbind('meta+h');
  } 

	render() {
    return(
    	<div className="mousetrap">
    		{this.state.pressed && (
	      	<Redirect to = {{pathname: "/"}} />
	      )}
      </div>
    )
  }
}

export default RedirectToHome;