import React, { Component } from "react";
import { MobileView } from "react-device-detect";

import Navbar from './navbar'
import MobileButton from './mobileButton';
import RedirectToHome from './redirectToHome'

import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Alert from 'react-bootstrap/Alert';

import mainStyles from '../styles/mainStyles.js';
import '../styles/bootstrapOverwriteStyles.css';

const Mousetrap = require("mousetrap");

class Result extends Component {

  constructor(props) {
    super(props);
    this.state = {text: '', isProtected: false, passwordIncorrect: false, password: '', isUpload: false, slug: ''} 
    this.onChangePassword = this.onChangePassword.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
  }
  
  preserveSpacing(text) {
    let newText = text.split('\n').map((item, i) => {
      return <div key={i}>{item}</div>;
    })
    return newText
  }

  async componentDidMount() {
    Mousetrap.bind(['ctrl+c', 'meta+c'], (e) => {
      navigator.clipboard.writeText(window.location)
    })
    
    //redirected from homepage thus text is in props
    if (this.props.location.state) {
      this.setState({isUpload: true})
      setTimeout(() => {this.setState({isUpload: false})}, 500)
      this.setState({text: this.preserveSpacing(this.props.location.state.text)})
    }
    //else get slug from params & text from server
    else {
      const slug = this.props.match.params.id
      let responseRaw = await fetch(process.env.REACT_APP_BASE_URL + '/bin/' + slug , {
        method: 'GET',
        mode: 'cors'
      })
      if(responseRaw.status === 200 || responseRaw.status === 404) { //got a normal response
        let response = await responseRaw.json();
        this.setState({text: this.preserveSpacing(response.text)})
      }
      else  {
        this.setState({isProtected: true})
        this.setState({slug: this.props.match.params.id}) 
      }
    }
  }
  
  onChangePassword(e) {
    if(e.key === 'Enter'){
      this.onSubmit(e)
    }
    this.setState({password: e.target.value})
  }

  async onSubmit(e) { 
    e.preventDefault();
    const data = {password: this.state.password}
    let responseRaw = await fetch(process.env.REACT_APP_BASE_URL + '/bin/' + this.state.slug,{
      method: 'POST',
      mode: 'cors',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(data)
    })
    if(responseRaw.status === 200 || responseRaw.status === 404) { //password correct
      let response = await responseRaw.json();
      this.setState({text: this.preserveSpacing(response.text)})
      this.setState({isProtected: false})
    }
    else  { 
      this.setState({passwordIncorrect: true})
      setTimeout(() => {this.setState({passwordIncorrect: false})}, 1000)
    }
  } 
  
  render() {
    return(
      <Container style={mainStyles.containerStyles} fluid={true}>
        <Navbar/>
        <RedirectToHome/>
        <Form onSubmit={this.onSubmit}>
          <Modal className="clean-modal" show={this.state.isProtected}>
            <Modal.Header style={mainStyles.primaryStyles}  closeButton>
              <Modal.Title>We need a password </Modal.Title>
            </Modal.Header>
            <Modal.Body style={mainStyles.secondaryStyles}>
              {this.state.passwordIncorrect && (
                <Alert className="alert">
                Password incorrect!
                </Alert>
              )}
              <Form.Group controlId="formBasicPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control style={mainStyles.secondaryStyles} type="password" onChange={this.onChangePassword} onKeyPress={this.onChangePassword} placeholder="Password" />
              </Form.Group>
            </Modal.Body>
            <Modal.Footer style={mainStyles.primaryStyles}>
              <Button size="lg" variant="primary" onClick={this.onSubmit} block>
                Submit password!
              </Button>
            </Modal.Footer>
          </Modal>
        </Form>
        <MobileButton icon={'home'} onClick=""/>
        <p style={mainStyles.mainAreaStyles}>
          {this.state.isUpload && (
            <MobileView>
              <Alert className="alert-success">
                Your pastebin is now live!
              </Alert>
            </MobileView>
          )} 
          {this.state.text} 
        </p>
      </Container>
    )
  }
}

export default Result;
