const questionStyles = {
	paddingTop: 25, 
 	paddingLeft: 20
}

const answerStyles = {
	paddingLeft: 40,
	paddingRight: 20
}

const lastAnswerStyles = {
	paddingLeft: 40,
	paddingRight: 20,
	paddingBottom: '9vh'
}

const linkStyles = {
	textDecoration: 'underline',
	color: '#ebebeb'
}

export default {questionStyles, answerStyles, lastAnswerStyles, linkStyles}